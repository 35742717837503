<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="admin-grid">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="filter">
        Filter by Intended Date:
        <select
          v-model="selectedFilter"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterDate" :key="item">
            {{ item }}
          </option>
        </select>
        <select
          v-if="$store.state.getUserType === 'admin-moderator'"
          v-model="selectedDepartment"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option>All</option>
          <option v-for="item in departmentItem" :key="item">
            {{ item.deptName }}
          </option>
        </select>
        <select
          v-model="selectedStatus"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterStat" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="payment-grid">
        <div v-if="isNotFound">
          <h3>{{ messageNotFound }}</h3>
        </div>
        <div v-if="isRender" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <div v-else v-for="(transaction, index) in filterItem" :key="index">
          <div class="admin-card accordion">
            <div style="display: flex">
              <div class="accordion-content">
                <div>
                  <span class="card-date subtitle"
                    >Date Intended:
                    {{ dateFormat(transaction.dateStart, "DI") }}
                  </span>
                </div>
                <div>
                  <span class="card-date subtitle"
                    >Requested by: {{ transaction.requestedBy }}
                  </span>
                </div>
                <div>
                  <span class="card-date subtitle"
                    >Requested date:
                    {{ dateFormat(transaction.createdAt, "DR") }}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <input
                @click="routeModal(index)"
                type="submit"
                :value="
                  !transaction.status
                    ? 'For Pre-approved'
                    : $store.state.getUserType === 'admin-moderator' &&
                      transaction.status === 'Approved'
                    ? 'Pending'
                    : transaction.status
                "
                class="btn-status"
                :class="[
                  !transaction.status ||
                  transaction.status === 'Completion' ||
                  ($store.state.getUserType === 'admin-moderator' &&
                    transaction.status === 'Approved')
                    ? 'status-pending'
                    : transaction.status === 'Pre-approved'
                    ? 'status-pre-approved'
                    : transaction.status === 'Completed'
                    ? 'status-completed'
                    : transaction.status === 'Cancelled'
                    ? 'status-cancelled'
                    : transaction.status === 'Disapproved'
                    ? 'status-declined'
                    : transaction.status === 'Approved'
                    ? 'status-approved'
                    : 'status-revert',
                ]"
              />
            </div>
          </div>
          <div
            class="panel"
            v-if="index === indexSelected && ifExpand ? true : false"
          >
            <SectionOvertimeStatus
              :transaction="transaction"
            ></SectionOvertimeStatus>
            <div class="panel-payment-details">
              <SectionOvertimeDetails
                :transaction="transaction"
              ></SectionOvertimeDetails>
              <div class="btn-action-container">
                <input
                  v-if="actionApprover(transaction)"
                  class="btn btn-action"
                  type="submit"
                  value="Action"
                  @click="isModal = true"
                />
                <input
                  class="btn btn-close"
                  type="submit"
                  value="Close"
                  @click="ifExpand = false"
                />
              </div>
              <div v-if="isModal">
                <app-modal-overtime-approval
                  :ishaveSupervisor="haveSupervisor"
                  :requestId="transaction.id"
                  :dataItems="transaction"
                  :transaction="transactions"
                  :employeeItems="employeeList"
                  @passClose="getValue"
                ></app-modal-overtime-approval>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-record">
          <div>Total Record(s): {{ total_record }}</div>
        </div>
        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionOvertimeDetails from "@/components/partials/overtime/AuthOvertimeDetails.vue";
import SectionOvertimeStatus from "@/components/partials/overtime/AuthOvertimeStatusTracker.vue";
import { ref, computed, onActivated } from "vue";
import { inject } from "vue";
export default {
  components: {
    SectionOvertimeDetails,
    SectionOvertimeStatus,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const ifExpand = ref(false);
    const indexSelected = ref(0);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const messageNotFound = ref("");
    const isNotFound = ref(false);
    const showEntries = ref([5, 10, 25, 50]);
    const isRender = ref(true);
    const showFilterDate = ref([
      "Today",
      "Incoming",
      "This Month",
      "Last 7 Days",
      "Last Month",
      "Year to Date",
      "All Time",
    ]);
    const showFilterStatus = ref([
      "All",
      "Pending",
      "Pre-approved",
      "Approved",
      "Completed",
      "Disapproved",
    ]);
    const selectedEntry = ref(5);
    const total_amount = ref(0);
    const transactions = ref([]);
    const fetchItemList = ref([]);
    const employeeList = ref([]);
    const selectedFilter = ref("All Time");
    const selectedStatus = ref("Pending");
    const selectedDepartment = ref("All");
    const expandPanel = ref(false);
    const departmentItem = ref([]);
    const haveSupervisor = ref(0);
    const ifSupervisor = ref(store.state.getProfileInfo.ifSuperVisor);

    onActivated(() => {
      getDepartment();
    });
    const filterItem = computed(() => {
      return modified.value;
    });
    const filterStat = computed(() => {
      return showFilterStatus.value.filter((stat) => {
        if (store.state.getUserType === "admin-moderator") {
          return stat !== "Pre-approved" && stat !== "Approved";
        } else {
          return stat;
        }
      });
    });
    const filterDate = computed(() => {
      return showFilterDate.value.filter((stat) => {
        return stat;
      });
    });

    const routeModal = (val) => {
      indexSelected.value = val;
      ifExpand.value = !ifExpand.value;
    };
    const actionApprover = (val) => {
      if (store.state.getUserType === "admin-moderator") {
        return val.status === "Approved";
      } else if (ifSupervisor.value) {
        return val.status === "Completion" || !val.status;
      } else {
        return (
          !val.status ||
          val.status === "Completion" ||
          val.status === "Pre-qualified"
        );
      }
    };
    const getValue = (
      val,
      payload,
      reqId,
      status,
      date,
      approverRemarks,
      approver,
      actionStatus
    ) => {
      isModal.value = val;
      if (payload) {
        setTimeout(async () => {
          let itemData;

          itemData = transactions.value.find((val) => {
            return val.id === reqId;
          });

          itemData.status = status;
          itemData.dtApproved = date;
          itemData.statusRemarks = approverRemarks;
          itemData.approverBy = approver;
          itemData.actionStatus = actionStatus;
          getFilterAction();
          store.commit("notificationCounterOvertime", transactions.value);

          expandPanel.value = false;
          ifExpand.value = false;
        }, 2000);
      }
    };

    const getFilterAction = (val) => {
      let userLogIn = store.state.getUserType;
      let selectedEntry = val;
      fetchItemList.value = transactions.value
        .filter((post) => {
          let referenceNumber = post.id + 100000;
          referenceNumber = referenceNumber.toString();
          return (
            post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            referenceNumber
              .toLowerCase()
              .includes(searchValue.value.toLowerCase())
          );
        })
        .filter((val) => {
          let dateSelected;
          const currentDate = store.state.currentDate;
          const lastSevenDays = moment(currentDate)
            .subtract(7, "days")
            .format("YYYY-MM-DD");

          const thisMonth = moment(currentDate)
            .subtract(moment().format("DD"), "days")
            .format("YYYY-MM-DD");

          const lastMonth = moment(currentDate)
            .subtract(1, "months")
            .format("YYYY-MM");

          const yearDate = moment(currentDate).format("YYYY");

          if (selectedFilter.value === "Today") {
            dateSelected = val.dateStart === currentDate;
          } else if (selectedFilter.value === "Last 7 Days") {
            dateSelected = val.dateStart > lastSevenDays;
          } else if (selectedFilter.value === "This Month") {
            dateSelected = val.dateStart > thisMonth;
          } else if (selectedFilter.value === "Last Month") {
            dateSelected =
              moment(val.dateStart).format("YYYY-MM") === lastMonth;
          } else if (selectedFilter.value === "Year to Date") {
            dateSelected = moment(val.dateStart).format("YYYY") === yearDate;
          } else if (selectedFilter.value === "Incoming") {
            dateSelected = val.dateStart > currentDate;
          } else {
            dateSelected = val;
          }
          return dateSelected;
        })
        .filter((role) => {
          let userType;
          switch (userLogIn) {
            case "admin-moderator":
              userType = role.actionStatus >= 4;
              break;
            /*Supervisor*/
            case "cohead-moderator":
            case "head-supervisor":
            case "operator-moderator":
              if (role.department === store.state.getDepartment) {
                let designation = store.state.getProfileInfo.designation;

                if (designation === "Health Provider Relations - Supervisor") {
                  userType =
                    role.actionStatus >= 1 &&
                    role.designation === "Health Provider Relations Officer" &&
                    role.requestedBy !== store.state.getUserLog;
                } else if (
                  designation === "Patient Relations Coordinator - Supervisor"
                ) {
                  userType =
                    role.actionStatus >= 1 &&
                    role.designation !== "Health Provider Relations Officer" &&
                    role.designation !==
                      "Health Provider Relations - Supervisor" &&
                    role.requestedBy !== store.state.getUserLog;
                } else if (
                  designation === "Facilities and Properties, GSD Supervisor"
                ) {
                  userType =
                    role.actionStatus >= 1 &&
                    (role.designation === "Messenger" ||
                      role.designation === "Company Driver" ||
                      role.designation === "Utility Worker" ||
                      role.designation === "Admin Support Assistant") &&
                    role.requestedBy !== store.state.getUserLog;
                } else if (
                  designation === "Policy and Claims Department - Head"
                ) {
                  userType =
                    role.actionStatus >= 1 &&
                    (role.designation === "Policy Administration Assistant" ||
                      role.designation === "Policy and Admin Support Staff" ||
                      role.designation === "Policy Insurance Coordinator") &&
                    role.requestedBy !== store.state.getUserLog;
                } else {
                  userType =
                    role.actionStatus >= 1 &&
                    role.department === store.state.getDepartment &&
                    role.requestedBy !== store.state.getUserLog;
                }
              }

              break;
            case "supervisor-moderator":
            case "head":
            case "semi-moderator":
            case "moderator":
            case "pre-moderator":
            case "super":
              if (haveSupervisor.value) {
                let isSuperVisor = role.User.ifSuperVisor;
                if (userLogIn === "semi-moderator") {
                  if (
                    role.department === "Astral Clinic" ||
                    role.department === "Corporate Services" ||
                    role.department === "Muntinlupa Clinic"
                  ) {
                    if (isSuperVisor) {
                      userType =
                        role.actionStatus >= 1 &&
                        (role.department === "Astral Clinic" ||
                          role.department === "Corporate Services" ||
                          role.department === "Muntinlupa Clinic") &&
                        role.requestedBy !== store.state.getUserLog;
                    } else {
                      userType =
                        (role.requestedBy !== store.state.getUserLog &&
                          role.actionStatus >= 1 &&
                          role.department === "Corporate Services" &&
                          role.designation !== "Messenger" &&
                          role.designation !== "Company Driver" &&
                          role.designation !== "Utility Worker") ||
                        (role.actionStatus > 2 &&
                          role.department === "Astral Clinic" &&
                          role.requestedBy !== store.state.getUserLog) ||
                        (role.requestedBy !== store.state.getUserLog &&
                          role.actionStatus > 2 &&
                          role.department === "Corporate Services" &&
                          (role.designation === "Messenger" ||
                            role.designation === "Company Driver" ||
                            role.designation === "Utility Worker"));
                    }
                  }
                }
                if (userLogIn === "moderator") {
                  if (
                    role.department === "Health Provider & Patient Relations"
                  ) {
                    if (isSuperVisor) {
                      userType =
                        role.actionStatus >= 1 &&
                        role.department ===
                          "Health Provider & Patient Relations" &&
                        role.requestedBy !== store.state.getUserLog;
                    } else {
                      userType =
                        role.actionStatus >= 3 &&
                        role.department ===
                          "Health Provider & Patient Relations" &&
                        role.requestedBy !== store.state.getUserLog;
                    }
                  }
                  if (role.department === "Marketing") {
                    userType =
                      role.actionStatus >= 1 &&
                      role.department === "Marketing" &&
                      role.requestedBy !== store.state.getUserLog;
                  }
                }
                if (userLogIn === "head") {
                  if (isSuperVisor) {
                    userType =
                      role.actionStatus >= 1 &&
                      role.department === store.state.getDepartment &&
                      role.requestedBy !== store.state.getUserLog;
                  } else {
                    userType =
                      role.actionStatus >= 3 &&
                      role.department === store.state.getDepartment &&
                      role.requestedBy !== store.state.getUserLog;
                  }
                }
              } else {
                userType =
                  role.actionStatus >= 1 &&
                  role.department === store.state.getDepartment &&
                  role.requestedBy !== store.state.getUserLog;
              }

              break;

            default:
              userType;
          }
          return userType;
        })
        .filter((stat) => {
          let status;

          if (selectedStatus.value === "All") {
            status = stat;
          } else if (selectedStatus.value === "Pending") {
            if (ifSupervisor.value) {
              status = !stat.status || stat.status === "Completion";
            } else {
              if (userLogIn === "admin-moderator") {
                status = stat.status === "Approved";
              } else {
                status =
                  !stat.status ||
                  stat.status === "Completion" ||
                  stat.status === "Pre-qualified";
              }
            }
          } else {
            status = stat.status === selectedStatus.value;
          }
          return status;
        })
        .filter((item) => {
          let department = selectedDepartment.value;
          let result;
          if (department === "All") {
            result = item;
          } else {
            result = department === item.department;
          }

          return result;
        });
      pagination(0, selectedEntry);
    };
    const pagination = (page, pr_page) => {
      let data = fetchItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 5;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;
      isNotFound.value = !modified.value.length ? true : false;
      messageNotFound.value = searchValue.value
        ? `We couldn't find anything for ${searchValue.value.toLowerCase()}. Try a new search.`
        : `No transactions yet. Want to try again with different status?`;

      total_record.value = data.length;
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const getAPIrequest = async () => {
      try {
        const response = await axios.get(`/api/admin/employeee/overtime`);
        isNotFound.value = response.data.length ? false : true;
        transactions.value = response.data;
        store.commit("notificationCounterOvertime", transactions.value);
        getFilterAction();
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val, stat) => {
      if (stat === "DI") {
        return val ? moment(val).format("ll") : "-";
      } else {
        return val ? moment(val).format("LLL") : "-";
      }
    };
    const dayFormat = (val) => {
      return val ? moment(val).format("dddd") : "-";
    };
    const getDepartment = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        departmentItem.value = response.data;

        let res = departmentItem.value.find((val) => {
          return val.deptName === store.state.getDepartment;
        });
        haveSupervisor.value = res.haveSuperVisor;
        getAPIrequest();
        console.log(haveSupervisor.value);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      isModal,
      showEntries,
      selectedEntry,
      searchValue,
      transactions,
      fetchItemList,
      selectedStatus,
      showFilterStatus,
      ifExpand,
      indexSelected,
      filterItem,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      messageNotFound,
      isNotFound,
      total_amount,
      selectedFilter,
      showFilterDate,
      expandPanel,
      filterStat,
      filterDate,
      prevRecords,
      nextRecords,
      actionApprover,
      getFilterAction,
      routeModal,
      pagination,
      getAPIrequest,
      dateFormat,
      dayFormat,
      getValue,
      selectedDepartment,
      getDepartment,
      departmentItem,
      employeeList,
      haveSupervisor,
      ifSupervisor,
      isRender,
    };
  },
};
</script>

<style scoped>
.subtitle {
  color: rgb(49, 48, 48);
  font-weight: 500;
}
.status-pre-approved {
  background-color: #3496e6;
}
.status-pre-approved:hover,
.status-pre-approved:focus {
  background-color: #3496e6;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-pre-approved:active {
  background-color: #0073df;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-pending {
  background-color: #f09409;
}

.status-completed {
  background-color: #1d9405;
}
.status-completed:hover,
.status-completed:focus {
  background-color: #1d9405;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-completed:active {
  background-color: #0e5f04;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-approved {
  background-color: rgb(86, 53, 233);
}
.status-approved:hover,
.status-approved:focus {
  background-color: rgb(86, 53, 233);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-approved:active {
  background-color: rgb(51, 24, 175);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-declined {
  background-color: #ad1105;
}
.status-declined:hover,
.status-declined:focus {
  background-color: #ad1105;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-declined:active {
  background-color: #81130b;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
</style>
